






















import {Vue, Component, Watch} from "vue-property-decorator";
import MyParticipateType from "./MyParticipate";
import ZoomPage from "../../../impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import MyParticipateNav from "@/views/Group/MyParticipate/components/Nav/index.vue";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import GroupHallItem from "@/views/Group/components/GroupItem/index.vue";
import { MyGroupList, MyJoinList } from './Server';
import {MyParticipateStore} from "@/views/Group/MyParticipate/Store";


@Component({name: "MyParticipate",components:{ HeadTop,MyParticipateNav,PullDownUpList,ListBottom,GroupHallItem }})
export default class MyParticipate extends ZoomPage implements MyParticipateType{
    navIndex = 0
    PullDown = false
    UpDownBool = true
    StopUp = false
    List:any[] = []

    mounted(){
        this.loadingShow = false
        this.List = MyParticipateStore.getMyParticipateList
        if ( this.List.length < 1 ){
            this.handleUpData(true)
        }
    }

    handleGetNumber(e:number){
        if ( this.navIndex != e ){
            this.navIndex = e;
            let upData = MyParticipateStore.getMyParticipateUpData
            upData.pageNo = 1
            MyParticipateStore.SetMyParticipateUpData(upData)
            this.UpDownBool = true
            this.handleUpData(true)
        }
    }

    handlePullDown(){
        this.PullDown = true
        let upData = MyParticipateStore.getMyParticipateUpData
        upData.pageNo = 1
        MyParticipateStore.SetMyParticipateUpData(upData)
        this.handleUpData(true)
    }

    handleUpDown(){
        if ( this.StopUp )return;
        let upData = MyParticipateStore.getMyParticipateUpData
        upData.pageNo += 1
        MyParticipateStore.SetMyParticipateUpData(upData)
        this.UpDownBool = true
        this.handleUpData()
    }

    handleUpData(bool?:boolean){
        if ( this.navIndex ){
            MyJoinList().then(res=>{
                this.PullDown = false
                this.UpDownBool = false
                if ( res.length < MyParticipateStore.getMyParticipateUpData.pageSize )this.StopUp = true;
                if ( bool )
                    MyParticipateStore.SetMyParticipateList( res );
                else
                    MyParticipateStore.SetMyParticipateList( MyParticipateStore.getMyParticipateList.concat(res) );
            })
        }else{
            MyGroupList().then(res=>{
                this.PullDown = false
                this.UpDownBool = false
                if ( res.length < MyParticipateStore.getMyParticipateUpData.pageSize )this.StopUp = true;
                if ( bool )
                    MyParticipateStore.SetMyParticipateList( res );
                else
                    MyParticipateStore.SetMyParticipateList( MyParticipateStore.getMyParticipateList.concat(res) );
            })
        }
    }

    get getList(){ return MyParticipateStore.getMyParticipateList }
    @Watch("getList")
    changeList(newVal:any[]){
        this.List = newVal
    }
}
